import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image'

import { BasicTemplate } from '../components/templates/basicTemplate'
import { GalleryPageTemplate } from '../components/templates/galleryPageTemplate'

import { useBasicSettings } from '../hooks/useBasicSettings'
import { useGalleryPageSettings } from '../hooks/useGalleryPageSettings'
import { getImageFileName } from '../utils/getImageFileName'
import { Work } from '../types/galleryPageSettings'

const GalleryPage = () => {
  const { basicSettings } = useBasicSettings()
  const { galleryPageSettings } = useGalleryPageSettings()

  const data = useStaticQuery(graphql`
    {
      allFile(filter: { extension: { regex: "/(jpg)|(png)/" } }) {
        nodes {
          relativePath
          childImageSharp {
            gatsbyImageData(
              formats: [AUTO, WEBP]
              placeholder: BLURRED
              quality: 95
              width: 600
              webpOptions: { quality: 95 }
            )
          }
        }
      }

      gif: allFile(filter: { extension: { eq: "gif" } }) {
        nodes {
          relativePath
          publicURL
        }
      }
    }
  `)

  const gifUrls: { [key: string]: string } = {}
  data.gif.nodes.forEach((n: { relativePath: string; publicURL: string }) => {
    gifUrls[n.relativePath] = n.publicURL
  })

  const illustrationImageNames: string[] = galleryPageSettings.illustration.map((il: Work) =>
    getImageFileName(il.image)
  )
  const cgWorksImageNames: string[] = galleryPageSettings.cgWorks.map((cg: Work) => getImageFileName(cg.image))
  const photographyImageNames: string[] = galleryPageSettings.photography.map((photo: Work) =>
    getImageFileName(photo.image)
  )

  const illustrationImageData: { [key: string]: IGatsbyImageData } = {}
  const cgWorksImageData: { [key: string]: IGatsbyImageData } = {}
  const photographyImageData: { [key: string]: IGatsbyImageData } = {}

  data.allFile.nodes.map((n: any) => {
    if (illustrationImageNames.includes(n.relativePath)) {
      const img = getImage(n)
      if (img) illustrationImageData[n.relativePath] = img
    }

    if (cgWorksImageNames.includes(n.relativePath)) {
      const img = getImage(n)
      if (img) cgWorksImageData[n.relativePath] = img
    }

    if (photographyImageNames.includes(n.relativePath)) {
      const img = getImage(n)
      if (img) photographyImageData[n.relativePath] = img
    }
  })

  const work = (image: IGatsbyImageData, w: Work, gif?: string): JSX.Element => (
    <>
      {gif ? (
        <img className={w.hover ? 'allowHover' : ''} src={gifUrls[gif]} alt={'work'} />
      ) : (
        <GatsbyImage className={w.hover ? 'allowHover' : ''} image={image} alt={'work'} />
      )}
      <div className={'info'}>
        <p>{w.title}</p>
        <div dangerouslySetInnerHTML={{ __html: w.description }} />
      </div>
    </>
  )

  /**
   * need to store by original order
   */
  const illustrationList = illustrationImageNames.map((name: string, i: number) =>
    work(illustrationImageData[name], galleryPageSettings.illustration[i])
  )
  const cgWorksList = cgWorksImageNames.map((name: string, i: number) => {
    const gif = name.split('.')[1] === 'gif' ? name : undefined

    return work(cgWorksImageData[name], galleryPageSettings.cgWorks[i], gif)
  })
  const photographyList = photographyImageNames.map((name: string, i: number) =>
    work(photographyImageData[name], galleryPageSettings.photography[i])
  )

  return (
    <BasicTemplate basicSettings={basicSettings}>
      <GalleryPageTemplate
        galleryPageSettings={galleryPageSettings}
        illustrationList={illustrationList}
        cgWorksList={cgWorksList}
        photographyList={photographyList}
      />
    </BasicTemplate>
  )
}

export default GalleryPage
