import { useStaticQuery, graphql } from 'gatsby'
import { GalleryPageSettings } from '../types/galleryPageSettings'

export const useGalleryPageSettings = (): { galleryPageSettings: GalleryPageSettings } => {
  const data = useStaticQuery(
    graphql`
      {
        markdownRemark(fileAbsolutePath: { regex: "/galleryPage.md/" }) {
          frontmatter {
            illustration {
              description
              hover
              image
              title
            }
            cgWorks {
              description
              hover
              image
              title
            }
            video {
              url
              videoTitle
            }
            photography {
              description
              hover
              image
              title
            }
          }
        }
      }
    `
  )

  return { galleryPageSettings: data.markdownRemark.frontmatter }
}
