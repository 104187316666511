import React from 'react'
import styled from 'styled-components'

interface Props {
  on: boolean
  closeLightbox: () => void
  lightboxContent: JSX.Element | null
}

export const LightboxModal = ({ on, closeLightbox, lightboxContent }: Props) => {
  return (
    <Lightbox on={String(on)} onClick={closeLightbox}>
      {lightboxContent}
    </Lightbox>
  )
}

const Lightbox = styled.div<any>`
  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  opacity: ${({ on }) => (on === 'true' ? 1 : 0)};
  background-color: rgba(100, 100, 100, 0.7);

  transition: opacity 0.4s;
  pointer-events: ${({ on }) => (on === 'true' ? 'initial' : 'none')};

  > *:first-child {
    width: 80vw;
    max-width: 600px;
    max-height: 80vh;
    object-fit: contain;
  }

  * {
    object-fit: contain !important;
  }
`
