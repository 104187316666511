import React from 'react'
import styled from 'styled-components'
import { Video } from '../types/galleryPageSettings'

interface Props {
  title: string
  list: JSX.Element[]
  openLightbox: (e: JSX.Element) => void
}

export const Gallery = ({ title, list, openLightbox }: Props) => {
  const column = () => {
    const columns: any = [[], [], []]

    list.forEach((elments: JSX.Element, i: number) =>
      columns[i % 3].push(
        <Work key={i} onClick={() => openLightbox(elments.props.children[0])}>
          {elments}
        </Work>
      )
    )

    return { one: columns[0], two: columns[1], three: columns[2] }
  }

  return (
    <Wrapper>
      <Header>
        <h2>{title}</h2>
        <Border />
      </Header>
      <GalleryDiv>
        <Column>{column().one}</Column>
        <Column>{column().two}</Column>
        <Column>{column().three}</Column>
      </GalleryDiv>
    </Wrapper>
  )
}

export const VideoGallery = ({ videos }: { videos: Video[] }) => {
  return (
    <Wrapper>
      <Header>
        <h2>Video</h2>
        <Border />
      </Header>
      <VideoDiv>
        {videos.map((video: any): any => (
          <div key={video.videoTitle}>
            <IframeWrapper>
              <iframe
                src={video.url}
                width={'100%'}
                height={'100%'}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </IframeWrapper>
            <p>{video.videoTitle}</p>
          </div>
        ))}
      </VideoDiv>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin: 30px 0 100px 0;
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  width: max-content;
  align-items: center;
  margin: 0 auto 40px auto;

  h2 {
    color: ${({ theme }) => theme.color.darkGrey};
    font-size: ${({ theme }) => theme.font.size.l};
    font-weight: ${({ theme }) => theme.font.weight['300']};
  }
`

const Border = styled.div`
  width: 70%;
  border-top: 1px solid black;
  margin-top: 5px;
`

const VideoDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  > div:not(:last-child) {
    margin-bottom: 90px;
  }

  p {
    margin-top: 10px;
    font-weight: ${({ theme }) => theme.font.weight['300']};
  }
`

const IframeWrapper = styled.div`
  width: 80vw;
  max-width: 800px;
  height: 45vw;
  max-height: 450px;

  iframe {
    min-height: 100%;
  }
`

const GalleryDiv = styled.div`
  display: grid;
  grid-column-gap: 20px;
  grid-template-columns: repeat(3, 1fr);

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    grid-column-gap: 10px;
  }
`

const Work = styled.div`
  position: relative;
  * {
    cursor: pointer;
  }

  > *:first-child.allowHover:hover + div {
    opacity: 1;
  }

  div.info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 5px 20px;

    opacity: 0;
    transition: opacity 0.5s;
    background: rgba(100, 100, 100, 0.5);

    color: white;
    font-size: min(1rem, 2vw);
    pointer-events: none;

    > div {
      font-weight: ${({ theme }) => theme.font.weight['300']};
    }
  }
`

const Column = styled.div`
  > div,
  > img {
    margin-bottom: 20px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    > div,
    > img {
      margin-bottom: 10px;
    }
  }
`
