import React, { useState } from 'react'
import { Center } from '../layout'
import { Gallery, VideoGallery } from '../gallery'
import { LightboxModal } from '../lightboxModal'
import { GalleryPageSettings } from '../../types/galleryPageSettings'

interface Props {
  galleryPageSettings: GalleryPageSettings
  illustrationList: JSX.Element[] | []
  cgWorksList: JSX.Element[] | []
  photographyList: JSX.Element[] | []
}

export const GalleryPageTemplate = (props: Props) => {
  const { galleryPageSettings, illustrationList, cgWorksList, photographyList } = props

  const [onLightbox, setOnLightbox] = useState<boolean>(false)
  const [lightboxContent, setLightboxContent] = useState<any>(null)

  const openLightbox = (element: JSX.Element) => {
    setOnLightbox(true)
    setLightboxContent(element)
  }

  return (
    <>
      <Center>
        <Gallery title={'Illustration'} list={illustrationList} openLightbox={openLightbox} />
        <Gallery title={'3D works'} list={cgWorksList} openLightbox={openLightbox} />
        <VideoGallery videos={galleryPageSettings.video} />
        <Gallery title={'Photography'} list={photographyList} openLightbox={openLightbox} />
      </Center>
      <LightboxModal on={onLightbox} closeLightbox={() => setOnLightbox(false)} lightboxContent={lightboxContent} />
    </>
  )
}
